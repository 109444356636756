import { TypeAnimation } from "react-type-animation";

export default function Hero() {
    return(
        <div class="relative isolate overflow-hidden bg-gray-900">
            <div class="area">
			    <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className="flex flex-row flex-row justify-end items-center flex-1 px-2 pt-2 pb-1">
                <figure ><img src="../../logo.png" alt="Apidoxy" width={200} /></figure>
                <div className="flex-1"></div>
                <button className="gsi-material-button" onClick={() => window.location.href="/auth/google"}>
                    <div className="gsi-material-button-state"></div>
                    <div className="gsi-material-button-content-wrapper">
                        <div className="gsi-material-button-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xlink="http://www.w3.org/1999/xlink" style={{display: "block"}}>
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                        </div>
                        <span className="gsi-material-button-contents">Sign in with Google</span>
                        <span style={{display: "none"}}>Sign in with Google</span>
                    </div>
                </button>
            </div>
            <svg
                class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true">
                <defs>
                <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="100%" y="-1"
                    patternUnits="userSpaceOnUse">
                    <path d="M.5 200V.5H200" fill="none"></path>
                </pattern>
                </defs>
                <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
                <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                    stroke-width="0"></path>
                </svg>
                <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"></rect>
            </svg>
            <div
                class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                aria-hidden="true">
                <div class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                style={{ clipPath: "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"}}>
                </div>
            </div>
            <div class="mt-[-50px] flex h-screen items-center justify-center">
                <div class="max-w-full flex-shrink-0 px-4 text-center lg:mx-0 lg:max-w-3xl lg:pt-8">
                <h1 class="mt-10 text-5xl font-bold tracking-tight text-white sm:text-6xl">
                    Shout out your&nbsp;
                    <span class="text-sky-500">
                    <TypeAnimation
                        sequence={[
                            'business',
                            1000,
                            'leadership',
                            1000,
                            'network',
                            1000
                        ]}
                    wrapper="span"
                    speed={50}
                    className="select-none text-sky-500"
                    style={{ fontSize: '64px', width: 240, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }}
                    repeat={Infinity} />
                    </span> with&nbsp;
                    <span class="text-sky-500">apiDoxy</span>
                </h1>
                <p class="mt-6 text-lg leading-8 text-gray-300">Just with a mobile app</p>

                <div class="mt-5 flex items-center justify-center gap-x-6">
                    <button className="btn btn-solid-primary" onClick={() => window.location.href="/auth/google" }>Get started</button>
                </div>
                </div>
            </div>
        </div>
    )
}