export default function Pricing() {
    return(
        <div class="bg-gray-50">

    <main>
      <div>
        <div class="relative bg-indigo-600">
          <div aria-hidden="true" class="absolute bottom-0 hidden h-6 w-full bg-gray-50 lg:block"></div>

          <div class="relative mx-auto max-w-2xl px-6 pt-16 text-center sm:pt-32 lg:max-w-7xl lg:px-8">
            <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              <span class="block lg:inline">Simple pricing,&nbsp;</span>
              <span class="block lg:inline">no commitment.</span>
            </h1>
            <p class="mt-4 text-xl text-indigo-100">Everything you need, nothing you don't. Pick a plan that best suits your business.</p>
          </div>

          <h2 class="sr-only">Plans</h2>


          <div class="relative mx-auto mt-8 max-w-2xl px-6 pb-8 sm:mt-12 lg:max-w-7xl lg:px-8 lg:pb-0">

            <div aria-hidden="true" class="absolute inset-0 top-4 bottom-6 left-8 right-8 hidden rounded-tl-lg rounded-tr-lg bg-indigo-700 lg:block"></div>

            <div class="relative space-y-6 lg:grid lg:grid-cols-3 lg:space-y-0">
              
                <div class="bg-indigo-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                  <div>
                    <h3 class="text-white text-base font-semibold">Starter</h3>
                    <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                      <div class="mt-3 flex items-center">
                        <p class="text-white text-4xl font-bold tracking-tight">$0</p>
                        <div class="ml-4">
                          <p class="text-white text-sm">USD / mo</p>
                          <p class="text-indigo-200 text-sm">Billed yearly ($0)</p>
                        </div>
                      </div>
                      <a href="/auth/google" class="bg-white text-indigo-600 hover:bg-indigo-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full">Buy Starter</a>
                    </div>
                  </div>
                  <h4 class="sr-only">Features</h4>
                  <ul role="list" class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0">
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">5 projects</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">Unlimited reuse</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">Android app</span>
                      </li>
                    
                  </ul>
                </div>
              
                <div class="bg-white ring-2 ring-indigo-700 shadow-md pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                  <div>
                    <h3 class="text-indigo-600 text-base font-semibold">Scale</h3>
                    <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                      <div class="mt-3 flex items-center">
                        <p class="text-indigo-600 text-4xl font-bold tracking-tight">$5</p>
                        <div class="ml-4">
                          <p class="text-gray-700 text-sm">USD / mo</p>
                          <p class="text-gray-500 text-sm">Billed yearly ($56)</p>
                        </div>
                      </div>
                      <a href="#scale" class="cursor-not-allowed pointer-events-none bg-indigo-600 text-white hover:bg-indigo-700 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full">Buy Scale</a>
                    </div>
                  </div>
                  <h4 class="sr-only">Features</h4>
                  <ul role="list" class="border-gray-200 divide-gray-200 mt-7 border-t divide-y lg:border-t-0">
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-gray-600 ml-4 text-sm font-medium">Unlimited projects</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-gray-600 ml-4 text-sm font-medium">Unlimited reuse</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-gray-600 ml-4 text-sm font-medium">Android app</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-gray-600 ml-4 text-sm font-medium">iOS app</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-gray-600 ml-4 text-sm font-medium">One month trial</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-gray-600 ml-4 text-sm font-medium">Live support</span>
                      </li>
                    
                  </ul>
                </div>
              
                <div class="bg-indigo-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                  <div>
                    <h3 class="text-white text-base font-semibold">Enterprise</h3>
                    <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                      <div class="mt-3 flex items-center">
                        
                        <div class="ml-0">
                          <p class="text-white text-sm">Customized</p>
                          <p class="text-indigo-200 text-sm">Billed yearly (customised)</p>
                        </div>
                      </div>
                      <a href="mailto:support@apidoxy.com" class="bg-white text-indigo-600 hover:bg-indigo-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full">Contact us</a>
                    </div>
                  </div>
                  <h4 class="sr-only">Features</h4>
                  <ul role="list" class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0">
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">Unlimited projects</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">Unlimited reuse</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">Source code</span>
                      </li>
                    
                      <li class="flex items-center py-3">
                        <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                        <span class="text-white ml-4 text-sm font-medium">Live support</span>
                      </li>
                    
                  </ul>
                </div>
              
            </div>
          </div>
        </div>


        <section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
          <h2 id="mobile-comparison-heading" class="sr-only">Feature comparison</h2>

          <div class="mx-auto mt-16 max-w-2xl space-y-16 px-6">
            
              <div class="border-t border-gray-200">
                <div class="border-transparent -mt-px pt-6 border-t-2 sm:w-1/2">
                  <h3 class="text-gray-900 text-sm font-bold">Starter</h3>
                  <p class="mt-2 text-sm text-gray-500">Make simple web view app within few minutes</p>
                </div>
                <h4 class="mt-10 text-sm font-bold text-gray-900">Technical</h4>

                <div class="relative mt-6">

                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                  </div>

                  <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl class="divide-y divide-gray-200">
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Project</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            <span class="text-gray-900 text-sm font-medium">5 projects</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Reuse</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                              <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Project redeploy</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Web view</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Android (.apk)</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">iOS (.ipa)</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                              <span class="sr-only">No</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Package name</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                    </dl>
                  </div>


                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                  </div>
                </div>

                <h4 class="mt-10 text-sm font-bold text-gray-900">Other perks</h4>

                <div class="relative mt-6">

                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                  </div>

                  <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl class="divide-y divide-gray-200">
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">24/7 customer support</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Push notifications	</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">User statistics</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Source code</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Monetization</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Payment gateway</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">License</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                    </dl>
                  </div>


                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                  </div>
                </div>
              </div>
            
              <div class="border-t border-gray-200">
                <div class="border-indigo-600 -mt-px pt-6 border-t-2 sm:w-1/2">
                  <h3 class="text-indigo-600 text-sm font-bold">Scale</h3>
                  <p class="mt-2 text-sm text-gray-500">More strong editting facilities including Starter</p>
                </div>
                <h4 class="mt-10 text-sm font-bold text-gray-900">Catered for business</h4>

                <div class="relative mt-6">
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                  </div>

                  <div class="ring-2 ring-indigo-600 shadow-md relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl class="divide-y divide-gray-200">
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Project</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <span class="text-indigo-600 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Reuse</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <span class="text-indigo-600 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Rebuid</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <span class="text-indigo-600 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Web view</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Android (.apk)</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">iOS (.ipa)</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Package name</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                    </dl>
                  </div>

                 
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="ring-2 ring-indigo-600 absolute right-0 w-1/2 h-full rounded-lg"></div>
                  </div>
                </div>

                <h4 class="mt-10 text-sm font-bold text-gray-900">Other perks</h4>

                <div class="relative mt-6">
                  
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                  </div>

                  <div class="ring-2 ring-indigo-600 shadow-md relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl class="divide-y divide-gray-200">
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">24/7 customer support</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Push notifications	</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">User statistics</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Source code</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Monetization</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Payment gateway</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">License</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                    </dl>
                  </div>

                 
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="ring-2 ring-indigo-600 absolute right-0 w-1/2 h-full rounded-lg"></div>
                  </div>
                </div>
              </div>
            
              <div class="border-t border-gray-200">
                <div class="border-transparent -mt-px pt-6 border-t-2 sm:w-1/2">
                  <h3 class="text-gray-900 text-sm font-bold">Growth</h3>
                  <p class="mt-2 text-sm text-gray-500">Create your own plane with the best experience for your team</p>
                </div>
                <h4 class="mt-10 text-sm font-bold text-gray-900">Catered for business</h4>

                <div class="relative mt-6">
                  
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                  </div>

                  <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl class="divide-y divide-gray-200">
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Project</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                                
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Reuse</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                          <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Rebuid</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Web view</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Android (.apk)</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">iOS (.ipa)</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                        <div class="flex items-center justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="pr-4 text-sm font-medium text-gray-600">Package name</dt>
                          <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                            
                              
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </dd>
                        </div>
                      
                    </dl>
                  </div>

                 
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                  </div>
                </div>

                <h4 class="mt-10 text-sm font-bold text-gray-900">Other perks</h4>

                <div class="relative mt-6">
                  
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                  </div>

                  <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl class="divide-y divide-gray-200">
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">24/7 customer support</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Push notifications	</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">User statistics</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Source code</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Monetization</dt>
                          <dd class="text-center sm:px-4">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">Payment gateway</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                        <div class="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt class="text-sm font-medium text-gray-600 sm:pr-4">License</dt>
                          <dd class="text-center sm:px-4">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                          </dd>
                        </div>
                      
                    </dl>
                  </div>

                 
                  <div aria-hidden="true" class="pointer-events-none absolute inset-0 hidden sm:block">
                    <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                  </div>
                </div>
              </div>
            
          </div>
        </section>

        <section aria-labelledby="comparison-heading" class="hidden lg:block">
          <h2 id="comparison-heading" class="sr-only">Feature comparison</h2>

          <div class="mx-auto mt-24 max-w-7xl px-8">
            <div class="flex w-full items-stretch border-t border-gray-200">
              <div class="-mt-px flex w-1/4 items-end py-6 pr-4">
                <h3 class="mt-auto text-sm font-bold text-gray-900">Technical</h3>
              </div>
              
                <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
                  <div class="border-transparent py-6 border-t-2">
                    <p class="text-gray-900 text-sm font-bold">Starter</p>
                    <p class="mt-2 text-sm text-gray-500">Make simple web view app within few minutes</p>
                  </div>
                </div>
              
                <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
                  <div class="border-indigo-600 py-6 border-t-2">
                    <p class="text-indigo-600 text-sm font-bold">Scale</p>
                    <p class="mt-2 text-sm text-gray-500">More strong editting facilities including Starter</p>
                  </div>
                </div>
              
                <div aria-hidden="true" class=" -mt-px pl-4 w-1/4">
                  <div class="border-transparent py-6 border-t-2">
                    <p class="text-gray-900 text-sm font-bold">Enterprise</p>
                    <p class="mt-2 text-sm text-gray-500">Create your own plane with the best experience for your team</p>
                  </div>
                </div>
              
            </div>

            <div class="relative">
              
              <div class="pointer-events-none absolute inset-0 flex items-stretch" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg bg-white shadow"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg bg-white shadow-md"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="h-full w-full rounded-lg bg-white shadow"></div>
                </div>
              </div>

              <table class="relative w-full">
                <caption class="sr-only">
                  Business feature comparison
                </caption>
                <thead>
                  <tr class="text-left">
                    <th scope="col">
                      <span class="sr-only">Feature</span>
                    </th>
                    
                      <th scope="col">
                        <span class="sr-only">Starter plan</span>
                      </th>
                    
                      <th scope="col">
                        <span class="sr-only">Scale plan</span>
                      </th>
                    
                      <th scope="col">
                        <span class="sr-only">Enterprise plan</span>
                      </th>
                    
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-100">
                  
                    <tr key="Project">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Project</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                          <span class="text-gray-900 text-sm font-medium">5 projects</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <span class="text-indigo-600 text-sm font-medium">Unlimited</span>
                              
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Reuse">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Reuse</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <span class="text-indigo-600 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Rebuid">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Rebuid</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <span class="text-indigo-600 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <span class="text-gray-900 text-sm font-medium">Unlimited</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Web view">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Web view</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Android (.apk)">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Android (.apk)</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="iOS (.ipa)">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">iOS (.ipa)</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                              <span class="sr-only">No</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Package name">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Package name</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                                <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                              <span class="sr-only">Yes</span>
                            </span>
                        </td>
                      
                    </tr>
                  
                </tbody>
              </table>

              
              <div class="pointer-events-none absolute inset-0 flex items-stretch" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg ring-2 ring-indigo-600 ring-opacity-100"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="h-full w-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
              </div>
            </div>

            <h3 class="mt-10 text-sm font-bold text-gray-900">Other perks</h3>

            <div class="relative mt-6">
              
              <div class="pointer-events-none absolute inset-0 flex items-stretch" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg bg-white shadow"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg bg-white shadow-md"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="h-full w-full rounded-lg bg-white shadow"></div>
                </div>
              </div>

              <table class="relative w-full">
                <caption class="sr-only">
                  Perk comparison
                </caption>
                <thead>
                  <tr class="text-left">
                    <th scope="col">
                      <span class="sr-only">Perk</span>
                    </th>
                    
                      <th scope="col">
                        <span class="sr-only">Starter plan</span>
                      </th>
                    
                      <th scope="col">
                        <span class="sr-only">Scale plan</span>
                      </th>
                    
                      <th scope="col">
                        <span class="sr-only">Enterprise plan</span>
                      </th>
                    
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-100">
                  
                    <tr key="24/7 customer support">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">24/7 customer support</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Push notifications	">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Push notifications	</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="User statistics">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">User statistics</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Source code">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Source code</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Monetization">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Monetization</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="Payment gateway">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Payment gateway</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-gray-400" x-description="Heroicon name: mini/x-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
</svg>
                            <span class="sr-only">No</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                    <tr key="License">
                      <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">License</th>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                        <td class="px-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                              <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                        <td class="pl-4 relative w-1/4 py-0 text-center">
                          <span class="relative h-full w-full py-3">
                            
                          <svg class="mx-auto h-5 w-5 text-indigo-600" x-description="Heroicon name: mini/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
</svg>
                            <span class="sr-only">Yes</span>
                          </span>
                        </td>
                      
                    </tr>
                  
                </tbody>
              </table>


              <div class="pointer-events-none absolute inset-0 flex items-stretch" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="h-full w-full rounded-lg ring-2 ring-indigo-600 ring-opacity-100"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="h-full w-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      <div class="mx-auto max-w-2xl py-12 px-6 lg:max-w-7xl lg:py-32 lg:px-8">
        {/* <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple"/>
          </div>
          <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage"/>
          </div>
          <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg" alt="StaticKit"/>
          </div>
          <div class="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img class="h-12" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Transistor"/>
          </div>
          <div class="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <img class="h-12" src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg" alt="Workcation"/>
          </div>
        </div> */}
      </div>


      <section aria-labelledby="faq-heading" class="bg-gray-900">
        <div class="mx-auto max-w-2xl py-16 px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div class="max-w-2xl lg:mx-auto lg:text-center">
            <h2 id="faq-heading" class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Frequently asked questions</h2>
            <p class="mt-4 text-gray-400">Explore Our FAQ Section</p>
          </div>
          <div class="mt-20">
            <dl class="space-y-10 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10 lg:space-y-0">
              
                <div>
                  <dt class="font-semibold text-white">What is apiDoxy, and how does it simplify mobile app development?</dt>
                  <dd class="mt-3 text-gray-400">apiDoxy is a comprehensive CMS builder designed to simplify mobile app development by enabling users to create Android and iOS apps without writing a single line of code. Our platform offers intuitive drag-and-drop tools, customizable templates, and seamless integration options, streamlining the entire app development process from start to finish.</dd>
                </div>
              
                <div>
                  <dt class="font-semibold text-white">Do I need programming experience to use apiDoxy?</dt>
                  <dd class="mt-3 text-gray-400">No, you don't need any programming experience to use apiDoxy. Our platform is designed to be user-friendly and accessible to individuals with varying levels of technical expertise. Whether you're a seasoned developer or a complete beginner, you can easily leverage apiDoxy's features to create professional-quality mobile apps.</dd>
                </div>
              
                <div>
                  <dt class="font-semibold text-white">What platforms does apiDoxy support for app deployment?</dt>
                  <dd class="mt-3 text-gray-400">apiDoxy supports deployment to both Android and iOS platforms, ensuring that your app reaches a wide audience across major mobile operating systems. Whether you're targeting users on Android devices or iPhones and iPads, apiDoxy has you covered.</dd>
                </div>
              
                <div>
                  <dt class="font-semibold text-white">Can I customize my app's design and functionality with apiDoxy?</dt>
                  <dd class="mt-3 text-gray-400">Yes, apiDoxy offers extensive customization options to tailor your app's design and functionality to your specific needs. From choosing customizable templates to adjusting layouts, colors, fonts, and adding custom features, apiDoxy empowers you to create unique and engaging mobile experiences for your users.</dd>
                </div>
              
                <div>
                  <dt class="font-semibold text-white">How long does it take to build and launch an app with apiDoxy?</dt>
                  <dd class="mt-3 text-gray-400">The time required to build and launch an app with apiDoxy depends on various factors such as the complexity of your app, the extent of customization, and your familiarity with the platform. However, many users can create and launch basic apps in a matter of hours or days, thanks to apiDoxy's intuitive interface and pre-built components.</dd>
                </div>
              
                <div>
                  <dt class="font-semibold text-white">What support resources are available to apiDoxy users?</dt>
                  <dd class="mt-3 text-gray-400">apiDoxy offers a range of support resources to assist users throughout the app development process. This includes comprehensive documentation, step-by-step tutorials, email support, and access to a vibrant community forum where users can ask questions, share tips, and collaborate with fellow developers. Our goal is to ensure that you have all the support you need to succeed with apiDoxy.</dd>
                </div>
              
              
            </dl>
          </div>
        </div>
      </section>


      
    </main>
  </div>
    )
}