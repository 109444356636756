import { configureStore } from '@reduxjs/toolkit';
import webViewReducer from '../features/webView/webViewSlice';
import deviceReducer from '../features/webView/deviceSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice'

export const store = configureStore({
  reducer: {
    webView: webViewReducer,
    device: deviceReducer,
    dashboard: dashboardReducer
  },
});
