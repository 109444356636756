import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { setSplashColor, setSplashDuration, setSplashTitle } from './webViewSlice';

export default function SplashProperties() {
    const dispatch = useDispatch();
    const splashColor = useSelector((state) => state.webView.splashColor);
    const splashTitle = useSelector((state) => state.webView.splashTitle);
    const splashDuration = useSelector((state) => state.webView.splashDuration);
    return(
        <div className="flex flex-1 max-w-72">
            <aside className="sidebar h-full flex flex-col justify-start flex-1">
                <section className="sidebar-content h-full overflow-visible">
                    <nav className="menu rounded-md">
                        <section className="menu-section px-4">
                            <span className="menu-title select-none">Properties</span>
                            <div className="p-1">
                                <label class="form-control w-full max-w-xs flex flex-col">
                                    <div class="label ml-1">
                                        <span class="label-text">App title</span>
                                    </div>
                                    <input className="input input-bordered w-full max-w-xs" placeholder="App title" value={splashTitle} onChange={(e) => dispatch(setSplashTitle(e.target.value))} />
                                </label>
                            </div>
                            <div className="p-1">
                                <label class="form-control w-full max-w-xs flex flex-col">
                                    <div class="label ml-1">
                                        <span class="label-text">Splash duration (Seconds)</span>
                                    </div>
                                    <input type="number" min={2} max={10} className="input input-bordered w-full max-w-xs" placeholder="Splash duration (Seconds)" value={splashDuration} onChange={(e) => dispatch(setSplashDuration(e.target.value))} />
                                </label>
                            </div>
                            <div className="p-1 popover w-full">
                                <label class="form-control w-full max-w-xs flex flex-col">
                                    <div class="label ml-1">
                                        <span class="label-text">Splash Screen color</span>
                                    </div>
                                    <input className="popover-trigger input input-bordered w-full max-w-xs" placeholder="Screen color" readOnly value={splashColor}/>
                                    <div className="popover-content mt-16 p-0">
                                        <SketchPicker width={205} onChangeComplete={(c) => dispatch(setSplashColor(c.hex))}/>
                                    </div>
                                </label>
                            </div>
                        </section>
                    </nav>
                </section>
            </aside>
        </div>
    )
}