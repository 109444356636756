import Dashboard from "./features/dashboard/dashboard";
import Home from "./features/home/home";
import Privacy from "./features/privacy/privacy";
import Tos from "./features/tos/tos";
import WebViewEditor from "./features/webView/webView";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={ <Home /> }/>
        <Route path="/dashboard" element={ <Dashboard /> }/>
        <Route path="editor/:id" element={
          <div className="flex flex-col h-screen">
            <WebViewEditor />
          </div>
        }/>
        <Route path="/tos" element={ <Tos /> }/>
        <Route path="/privacy" element={ <Privacy /> }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
