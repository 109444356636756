export default function Tos() {
    return(
        <div class="max-w-4xl mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-6">Apidoxy Terms of Service (TOS)</h1>

        <p class="text-gray-700 mb-8">Last update: March 2024</p>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Introduction</h2>
            <p class="text-gray-700">Welcome to Apidoxy! These Terms of Service ("Terms") govern your use of the Apidoxy platform and services.</p>
            <p class="text-gray-700">Apidoxy offers a mobile application creation service that streamlines the app development process for individuals and businesses. Our platform provides a user-friendly interface with drag-and-drop tools and pre-built components, eliminating the need for extensive coding knowledge. We strive to make app development accessible and efficient for a wide range of users, from entrepreneurs to enterprises.</p>
            <p class="text-gray-700">These Terms outline the rules and guidelines for using our platform. Please read them carefully before accessing or using Apidoxy. By using our platform, you agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you may not access or use our platform.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Definitions</h2>
            <p class="text-gray-700">a. "Apidoxy" (collectively referred to as "we", "us" or "our") denotes the mobile application creation service provided by Apidoxy.</p>
            <p class="text-gray-700">b. "Platform" refers to the website, software or service provided by Apidoxy.</p>
            <p class="text-gray-700">c. "You" or "User" refers to any individual who accesses or utilizes Apidoxy's software, platform, or services.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Account Registration</h2>
            <p class="text-gray-700">3.1. Creating an Account: To access certain features of the Apidoxy platform, users must create an account. Account creation may require providing certain personal information, such as name, email address, and password. Users agree to provide accurate and complete information during the registration process.</p>
            <p class="text-gray-700">3.2 Account Security: Users are responsible for maintaining the confidentiality of their account credentials and for any activity that occurs under their account. Users agree to notify Apidoxy immediately of any unauthorized use of their account or any other security breach.</p>
            <p class="text-gray-700">3.3 Account Verification: Apidoxy reserves the right to verify the accuracy of account information provided by users. This may include requesting additional information or documentation to confirm user identity or ownership of content.</p>
            <p class="text-gray-700">3.4 Account Termination: Apidoxy reserves the right to suspend or terminate user accounts that violate these Terms of Service or for any other reason, at its sole discretion. Users may also terminate their accounts at any time by contacting Apidoxy customer support.</p>
            <p class="text-gray-700">3.5 Account Deletion: Upon termination of a user account, all associated data and content may be deleted from the Apidoxy platform. However, Apidoxy reserves the right to retain certain information as required by law or for legitimate business purposes.</p>
            <p class="text-gray-700">3.6 Account Transfer: Users may not transfer or assign their account to another party without prior written consent from Apidoxy.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Use of the Platform</h2>
            <p class="text-gray-700">4.1. Users may use the Apidoxy platform to create, edit, and manage mobile applications.</p>
            <p class="text-gray-700">4.2. Users agree not to use the platform for any unlawful or unauthorized purposes.</p>
            <p class="text-gray-700">4.3. Users are solely responsible for the content they create using the Apidoxy platform.</p>
        </div>


        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Intellectual Property</h2>
            <p class="text-gray-700">5.1. All content and materials provided on the Apidoxy platform, including templates, designs, and pre-built components, are the intellectual property of Apidoxy or its licensors.</p>
            <p class="text-gray-700">5.2. Users retain ownership of the unique content they create using the Apidoxy platform, such as custom application UI designs, custom images, and written texts.</p>
            <p class="text-gray-700">5.3. By using the Apidoxy platform, users grant Apidoxy a non-exclusive, royalty-free license to use, modify, and distribute their content solely for the purpose of providing and improving the platform.</p>
            <p class="text-gray-700">5.4. Users are responsible for ensuring they have the necessary rights to use any third-party content, such as images or logos, in their applications created with Apidoxy.</p>
            <p class="text-gray-700">5.5. Apidoxy respects the intellectual property rights of others and expects users to do the same. If you believe that your intellectual property rights have been infringed upon by a user of the Apidoxy platform, please contact us immediately.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Fees and Payments</h2>
            <p class="text-gray-700">6.1. Certain features of the Apidoxy platform may be subject to fees.</p>
            <p class="text-gray-700">6.2. Users agree to pay all fees associated with their use of the platform.</p>
        </div>


        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Termination</h2>
            <p class="text-gray-700">7.1. Apidoxy reserves the right to suspend or terminate user accounts for any reason, including but not limited to:</p>
            <ul class="list-disc ml-8">
                <li class="text-gray-700">a. Violation of these Terms of Service or any other policies or guidelines provided by Apidoxy.</li>
                <li class="text-gray-700">b. Conduct that Apidoxy determines to be harmful, abusive, or disruptive to other users or the operation of the platform.</li>
                <li class="text-gray-700">c. Suspected fraudulent, illegal, or unauthorized activities related to the use of the platform.</li>
            </ul>
            <p class="text-gray-700">7.2. Apidoxy may suspend or terminate user accounts without prior notice, at its sole discretion.</p>
            <p class="text-gray-700">7.3 Users may also terminate their accounts at any time by contacting Apidoxy customer support.</p>
            <p class="text-gray-700">7.8 Upon termination of a user account, all rights and licenses granted to the user under these Terms of Service will immediately cease.</p>
            <p class="text-gray-700">7.9. Apidoxy reserves the right to retain and use any information associated with a terminated user account as necessary for legal compliance, dispute resolution, or the protection of its rights and interests.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Disclaimer of Warranties</h2>
            <p class="text-gray-700">8.1. Apidoxy is provided on an "as is" and "as available" basis, without warranties of any kind.</p>
            <p class="text-gray-700">8.2. Apidoxy makes no warranties regarding the accuracy, reliability, or availability of the platform.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Limitation of Liability</h2>
            <p class="text-gray-700">Apidoxy shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way related to the use of the platform.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Changes to the Terms</h2>
            <p class="text-gray-700">10.1. Apidoxy reserves the right to modify or update these Terms at any time.</p>
            <p class="text-gray-700">10.2. Users will be notified of any changes to the Terms, and continued use of the platform constitutes acceptance of the revised Terms.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Governing Law</h2>
            <p class="text-gray-700">11.1. These Terms are governed by and constructed in accordance with the Laws of Bangladesh.</p>
        </div>

        <div class="mb-8">
            <h2 class="text-xl font-semibold mb-4">Contact Us</h2>
            <p class="text-gray-700">If you have any questions or concerns about these Terms, please contact us at <a href="mailto:support@apidoxy.com" class="text-blue-500">support@apidoxy.com</a>.</p>
        </div>


        <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Acceptable Use Policy (AUP)</h2>
            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">1. Introduction</h3>
                <p class="text-gray-700">This policy outlines the acceptable use of our platform and services. By using Apidoxy, you agree to comply with this AUP.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">2. Prohibited Activities</h3>
                <p class="text-gray-700">2.1. Illegal Activities: Users may not use Apidoxy for any unlawful purpose or to engage in any illegal activities.</p>
                <p class="text-gray-700">2.2. Abusive Behavior: Users may not harass, threaten, or abuse others, or engage in any behavior that is harmful, offensive, or inappropriate.</p>
                <p class="text-gray-700">2.3. Intellectual Property: Users may not infringe upon the intellectual property rights of others, including copyrights, trademarks, or trade secrets.</p>
                <p class="text-gray-700">2.4. Spamming: Users may not send unsolicited messages or emails, or engage in any form of spamming or phishing.</p>
                <p class="text-gray-700">2.5. Security Violations: Users may not attempt to bypass security measures, access unauthorized accounts or systems, or engage in any other activities that compromise the security or integrity of Apidoxy or its users.</p>
                <p class="text-gray-700">2.6. Misuse of Resources: Users may not use excessive bandwidth or storage, or engage in any activities that degrade the performance of Apidoxy for other users.</p>
                <p class="text-gray-700">2.7. Disabling Applications: Apidoxy reserves the right to disable or remove any application created using the platform, in cases where the application violates this AUP or poses a security risk to users or the platform itself.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">3. Reporting Violations</h3>
                <p class="text-gray-700">If you believe that someone has violated this AUP or engaged in any other inappropriate behavior on Apidoxy, please report it to us immediately. We will investigate all reports and take appropriate action as necessary.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">4. Enforcement</h3>
                <p class="text-gray-700">4.1. Violations of this AUP may result in the suspension or termination of your account or access to Apidoxy, as well as other legal or regulatory consequences.</p>
                <p class="text-gray-700">4.2. Apidoxy reserves the right to remove or disable any content or application that violates this AUP, without prior notice.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">5. Changes to this Policy</h3>
                <p class="text-gray-700">Apidoxy may update this AUP from time to time to reflect changes in our platform, services, or legal requirements. We will notify you of any material changes by posting the updated AUP on our website or by sending you a notification through our platform or other communication channels.</p>
            </div>
        </div>


        <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">End User License Agreement (EULA)</h2>
            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">1. Introduction</h3>
                <p class="text-gray-700">This agreement governs your use of the Apidoxy platform and services. By using Apidoxy, you agree to comply with this EULA.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">2. License Grant</h3>
                <p class="text-gray-700">2.1. Apidoxy grants you a limited, non-exclusive, non-transferable license to use the platform and its associated software for your personal or business use, subject to the terms and conditions of this EULA.</p>
                <p class="text-gray-700">2.2. Users may access and utilize the free features provided by Apidoxy, which are subject to the terms outlined in this EULA. Additionally, users have the option to obtain licenses for premium features or for full ownership based on Apidoxy's pricing policy.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">3. Ownership</h3>
                <p class="text-gray-700">3.1. Apidoxy retains all right, title, and interest in and to the platform, including all intellectual property rights therein. This EULA does not grant you any ownership rights to the platform or its software.</p>
                <p class="text-gray-700">3.2. Users may opt to acquire licenses for premium features or full ownership of their creations, subject to Apidoxy's pricing policy and terms outlined in this EULA.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">4. Restrictions</h3>
                <p class="text-gray-700">4.1. You may not copy, modify, distribute, sell, lease, sublicense, or otherwise transfer or exploit the platform or any part thereof without Apidoxy's prior written consent.</p>
                <p class="text-gray-700">4.2. You may not reverse engineer, decompile, or disassemble the platform, or attempt to derive the source code or underlying algorithms of the software.</p>
                <p class="text-gray-700">4.3. You may not use the platform in any manner that violates this EULA, the Acceptable Use Policy, or any applicable laws or regulations.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">5. Updates, Support, and Termination</h3>
                <p class="text-gray-700">5.1. Apidoxy may provide updates, upgrades, or patches to the platform from time to time. These updates may be provided automatically or may require manual installation.</p>
                <p class="text-gray-700">5.2. Apidoxy may also provide support services for the platform, which may include technical assistance, bug fixes, or other services as determined by Apidoxy in its sole discretion.</p>
                <p class="text-gray-700">5.3. This EULA may be terminated by Apidoxy at any time if you fail to comply with its terms and conditions. Upon termination, you must cease all use of the platform and destroy all copies of the software in your possession or control.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">6. Disclaimer of Warranty and Limitation of Liability</h3>
                <p class="text-gray-700">6.1. The platform is provided "as is" and "as available," without warranties of any kind. Apidoxy does not warrant that the platform will be error-free, uninterrupted, or free from security vulnerabilities.</p>
                <p class="text-gray-700">6.2. Apidoxy shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way related to your use of the platform or any third-party products or services accessed through the platform.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">7. Governing Law and Entire Agreement</h3>
                <p class="text-gray-700">7.1. This EULA is governed by and constructed in accordance with the laws of Bangladesh. Any disputes arising out of or in connection with this EULA shall be resolved exclusively by the courts of Bangladesh.</p>
                <p class="text-gray-700">7.2. This EULA constitutes the entire agreement between you and Apidoxy regarding your use of the platform, superseding any prior agreements or understandings, whether written or oral.</p>
            </div>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2">8. Contact Us</h3>
                <p class="text-gray-700">If you have any questions or concerns about this EULA, please contact us at <a href="mailto:support@apidoxy.com" class="text-blue-500">support@apidoxy.com</a>.</p>
            </div>
        </div>
    </div>
    )
}