import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function WebViewDevice() {
    const splashColor = useSelector((state) => state.webView.splashColor);
    const splashTitle = useSelector((state) => state.webView.splashTitle);
    const deviceType = useSelector((state) => state.device.deviceType);
    const image = useSelector((state) => state.webView.selectedAppIcon);
    const propertiesFor = useSelector((state) => state.webView.propertiesFor);
    const statusBarColor = useSelector((state) => state.webView.statusBarColor);
    return(
        <div className="flex flex-1">
                {   
                    deviceType === 0?
                    <div className="bg-[radial-gradient(#575757_1px,transparent_1px)] [background-size:16px_16px] flex-1 p-2">
                        <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
                            <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
                            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                            <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                            <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-black dark:bg-black">
                                {
                                    propertiesFor === 0 ?
                                    <div className="flex flex-col items-center justify-between h-full" style={{ background: splashColor }}>
                                        <div></div>
                                        <div className="flex flex-col items-center p-3">
                                            <img src={image} className={image ? "w-20 h-20": "hidden"} />
                                            <div className="font-normal p-4 text-lg text-center select-none">{splashTitle}</div>
                                        </div>
                                        <div className="flex flex-col items-center p-3">
                                            <div className="text-sm italic drop-shadow select-none">from</div>
                                            <div className="font-bold drop-shadow select-none">Apidoxy</div>
                                        </div>
                                    </div> : 
                                    <div className="flex flex-col items-center justify-start h-full" style={{ background: splashColor }}>
                                        <div className="flex flex-row px-4 py-1 w-full justify-between" style={{ background: statusBarColor }}>
                                            <span className="text-sm select-none">Network</span>
                                            <div className="w-full"></div>
                                            <span className="text-sm select-none">{moment().format('hh:mm')}</span>
                                        </div>
                                        <div className="flex-1 flex items-center justify-center w-full" style={{ backgroundColor: '#e5e5f7', opacity: .1, backgroundImage: 'repeating-linear-gradient( 45deg, #ffffff, #ffffff 2px, #e5e5f7 2px, #e5e5f7 10px )' }}>
                                            <span className="text-white dark:text-white">Website</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>:
                    <div className="bg-[radial-gradient(#575757_1px,transparent_1px)] [background-size:16px_16px] flex-1 p-2">
                        <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[454px] max-w-[341px] md:h-[682px] md:max-w-[512px]">
                            <div class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                            <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                            <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                            <div class="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                            <div class="rounded-[2rem] overflow-hidden h-[426px] md:h-[654px] bg-black dark:bg-black">
                                {
                                    propertiesFor === 0 ?
                                    <div className="flex flex-col items-center justify-between h-full" style={{ background: splashColor }}>
                                        <div></div>
                                        <div className="flex flex-col items-center p-3">
                                            <img src={image} className={image ? "w-20 h-20": "hidden"} />
                                            <div className="font-normal p-4 text-lg text-center select-none">{splashTitle}</div>
                                        </div>
                                        <div className="flex flex-col items-center p-3">
                                            <div className="text-sm italic drop-shadow select-none">from</div>
                                            <div className="font-bold drop-shadow select-none">Apidoxy</div>
                                        </div>
                                    </div> : 
                                    <div className="flex flex-col items-center justify-start h-full" style={{ background: splashColor }}>
                                        <div className="flex flex-row px-4 py-1 w-full justify-between" style={{ background: statusBarColor }}>
                                            <span className="text-sm select-none">Network</span>
                                            <div className="w-full"></div>
                                            <span className="text-sm select-none">{moment().format('hh:mm')}</span>
                                        </div>
                                        <div className="flex-1 flex items-center justify-center w-full" style={{ backgroundColor: '#e5e5f7', opacity: .1, backgroundImage: 'repeating-linear-gradient( 45deg, #ffffff, #ffffff 2px, #e5e5f7 2px, #e5e5f7 10px )' }}>
                                            <span className="text-white dark:text-white">Website</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                }
        </div>
    )
}