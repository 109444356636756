import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppIcon, setAppIcon, setApplicationName, setApplicationPackage } from './webViewSlice';
import Icon from '@mdi/react';
import { mdiCheck, mdiAlertCircleOutline  } from '@mdi/js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export default function Settings() {
    const [image, setImage] = useState(null);
    const [uploadState, setUploadState] = useState(0);
    const dispatch = useDispatch();
    const param = useParams();

    function toDataURL(file, callback){
        const src = URL.createObjectURL(file)
        var image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function(){

            if (!file.type.match('image/png')) {
                enqueueSnackbar('Image is not in PNG format', { autoHideDuration: 3000 })
                return;
            }

            // Check dimensions
            if (image.width > 512 || image.height > 512) {
                enqueueSnackbar('Image dimensions exceed 512 x 512', { autoHideDuration: 3000 })
                return;
            }
            
            // Check file size
            if (file.size > 1024 * 1024) { // 1 MB in bytes
                enqueueSnackbar('Image size exceeds 1 MB', { autoHideDuration: 3000 })
                return;
            }
           var canvas = document.createElement('canvas');
           var context = canvas.getContext('2d');
           canvas.height = this.naturalHeight;
           canvas.width = this.naturalWidth;
           context.drawImage(this, 0, 0);
           var dataURL = canvas.toDataURL('image/png');
           callback(dataURL);
        };
        image.src = src;
    }

    const notifier = (m) => {
        enqueueSnackbar(m, { autoHideDuration: 3000 })
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

                // convert image format and upload
                setImage(URL.createObjectURL(file));
                toDataURL(file, function(dataURL){
                    dispatch(selectAppIcon(dataURL));
                    setUploadState(1)
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('name', param.id);
                    axios.post('https://apidoxy.com/storefile', formData, {
                        'Content-Type': 'multipart/form-data',
                    }).then((res) => {
                        if(!res.data.error) {
                            dispatch(setAppIcon(res.data.media));
                            setUploadState(2)
                        } else {
                            setUploadState(3)
                        }
                    })
                });
            // };
        }
    }

    const applicationPackage = useSelector((state) => state.webView.applicationPackage);
    const applicationName = useSelector((state) => state.webView.applicationName);

    const isValidPackageName = (packageName) => {
        // Regular expression for Android package name validation
        const packageRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,148}(?:\.[^\W][\w.]{0,148})+$/;
        
        // List of restricted keywords in Java
        const restrictedKeywords = ['abstract', 'assert', 'boolean', 'break', 'byte', 'case', 'in', 'catch', 'char', 'class', 'const', 'continue', 'default', 'do', 'double', 'else', 'enum', 'extends', 'false', 'final', 'finally', 'float', 'for', 'goto', 'if', 'implements', 'import', 'instanceof', 'int', 'interface', 'long', 'native', 'new', 'null', 'package', 'private', 'protected', 'public', 'return', 'short', 'static', 'strictfp', 'super', 'switch', 'synchronized', 'this', 'throw', 'throws', 'transient', 'true', 'try', 'void', 'volatile', 'while'];
    
        // Check if the package name matches the regex and does not contain restricted keywords
        return packageRegex.test(packageName) && !restrictedKeywords.some(keyword => packageName.includes(keyword));
    }

    const isValidAppName = (applicationName) => {
        const regex = /^[a-zA-Z][a-zA-Z0-9\s]{1,28}[a-zA-Z0-9]$/;
        return regex.test(applicationName);
    }

    return(
        <div className="flex flex-1 max-w-72">
            <aside className="sidebar h-full flex flex-col justify-start flex-1">
                <section className="sidebar-content h-full overflow-visible">
                    <nav className="menu rounded-md">
                        <section className="menu-section px-4">
                            <span className="menu-title select-none">Properties</span>
                            <label class="form-control w-full max-w-xs flex flex-col">
                                <div class="label ml-1">
                                    <span class="label-text">App name</span>
                                </div>
                                <div className="p-1 flex flex-row items-center">
                                    <input className="input input-solid" placeholder="App name" value={applicationName} onChange={(e) => dispatch(setApplicationName(e.target.value))} />
                                    <div className="ml-2 tooltip tooltip-left tooltip-primary before:w-[15rem] before:content-[attr(data-tip)]" data-tip="App name must 3 - 30 character with A-Z a-z">
                                        <Icon path={ isValidAppName(applicationName) ? mdiCheck : mdiAlertCircleOutline } color={'green'} size={0.8} />
                                    </div>
                                </div>
                            </label>
                            <label class="form-control w-full max-w-xs flex flex-col">
                                <div class="label ml-1">
                                    <span class="label-text">Package name</span>
                                </div>
                                <div className="p-1 flex flex-row items-center">
                                    <input className="input input-solid" placeholder="com.company.product" value={applicationPackage} onChange={(e) => dispatch(setApplicationPackage(e.target.value))} />
                                    <div className="ml-2 tooltip tooltip-left tooltip-primary before:w-[15rem] before:content-[attr(data-tip)]" data-tip="Type a valid package name.">
                                        <Icon path={ isValidPackageName(applicationPackage) ? mdiCheck : mdiAlertCircleOutline } color={'green'} size={0.8} />
                                    </div>
                                </div> 
                                <div className='p-1'><a href="https://support.google.com/admob/answer/9972781?hl=en" className="link">Read more about package name</a></div>
                            </label>
                            <div className="p-1">
                                <label class="form-control w-full max-w-xs flex flex-col">
                                    <div class="label ml-1">
                                        <span class="label-text">App icon</span>
                                    </div>
                                    <input type="file" accept="image/png" class="input-file" onChange={onImageChange} />
                                </label>
                                <div className="flex flex-col py-2">
                                    <img alt="preview image" className={image ? 'w-16' : 'hidden'} src={image}/>
                                        {
                                            uploadState === 0?
                                            <span className={'text-xs select-none'}>Select a proper app icon with maximum height and width of 512 x 512 and maximum size of 1MB in png format</span>:
                                            uploadState === 1?
                                            <span className={'text-xs select-none'}>Uploading...</span>:
                                            uploadState === 2?
                                            <span className={'text-xs select-none'}>Uploaded</span>:
                                            <span className={'text-xs select-none'}>Uploade failed</span>
                                        }
                                    
                                </div>
                            </div> 
                        </section>
                    </nav>
                </section>
            </aside>
        </div>
    )
}