import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import isEmail from "validator/lib/isEmail"

export default function Suscriber() {
    const [email, setEmail] = useState('');
    const emailSub = (a) => {
        if(isEmail(a)) {
            enqueueSnackbar('Your are in now apiDoxy subscriber!', { variant: 'success' })
        } else {
            enqueueSnackbar('Incorrect email address', { variant: 'error' })
        }
    }
    return(
        <div className="relative bg-blue-600">
            <div className="absolute inset-x-0 bottom-0 ">
                <svg viewBox="0 0 224 12" fill="currentColor" className="w-full -mb-1 text-gray-800" preserveAspectRatio="none">
                    <path
                        d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z">
                    </path>
                </svg>
            </div>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                    <h2 className="mb-6 font-sans text-3xl text-center font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                    Subscribe to our newsletter
                    </h2>
                    <p className="mb-6 text-base text-indigo-200 md:text-lg">
                        Unlock Exclusive Insights, Tips, and Updates by joining Our Newsletter Community Today and Stay Informed About the Latest Trends, Expert Advice, and Product Developments. Be the First to Know, Connect with Like-Minded Professionals, and Take Your Business to New Heights with Our Valuable Newsletter Content.
                    </p>
                    <div className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                        <input className="input input-block input-success m-2" placeholder="Email adresss" onChange={(e) => setEmail(e.target.value)} />
                        <button class="btn btn-outline-success m-2" onClick={() => emailSub(email)}>Subscribe</button>
                    </div>
                    <p className="max-w-md mb-10 text-xs tracking-wide text-indigo-100 sm:text-sm sm:mx-auto md:mb-16">
                    Stay Updated, Stay Ahead.
                    </p>
                    <a href="#top" target="_top" aria-label="Scroll down"
                        className="flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform border border-gray-400 rounded-full hover:text-teal-accent-400 hover:border-teal-accent-400 hover:shadow hover:scale-110">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="currentColor">
                            <path
                                d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z">
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    )
}