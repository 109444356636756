import Icon from '@mdi/react';
import { mdiCogOutline, mdiFolderOpenOutline } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceType } from './deviceSlice';
import { setPropertiesFor, setTryTobuild } from './webViewSlice';
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { isURL } from 'validator';
import { useState } from 'react';

export default function WebViewAppBar() {
    const dispatch = useDispatch();
    const tryTobuild = useSelector((state) => state.webView.tryTobuild);
    const deviceType = useSelector((state) => state.device.deviceType);
    const projectTitle = useSelector((state) => state.webView.projectTitle);
    const projectDescription = useSelector((state) => state.webView.projectDescription);
    const splashScreenDuration = useSelector((state) => state.webView.splashDuration);
    const splashScreenColor = useSelector((state) => state.webView.splashColor);
    const appTitle = useSelector((state) => state.webView.splashTitle);
    const appName = useSelector((state) => state.webView.applicationName);
    const packageName = useSelector((state) => state.webView.applicationPackage);
    const websiteURL = useSelector((state) => state.webView.websiteURL);
    const statusBarColor = useSelector((state) => state.webView.statusBarColor);
    const appIcon = useSelector((state) => state.webView.appIcon);
    const param = useParams();
    const [finalState, setFinalState] = useState(false);

    const isValidPackageName = (packageName) => {
        // Regular expression for Android package name validation
        const packageRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,148}(?:\.[^\W][\w.]{0,148})+$/;
        
        // List of restricted keywords in Java
        const restrictedKeywords = ['abstract', 'assert', 'boolean', 'break', 'byte', 'case', 'catch', 'char', 'class', 'const', 'continue', 'default', 'do', 'double', 'else', 'enum', 'extends', 'false', 'final', 'finally', 'float', 'for', 'goto', 'if', 'implements', 'import', 'instanceof', 'int', 'interface', 'long', 'native', 'new', 'null', 'package', 'private', 'protected', 'public', 'return', 'short', 'static', 'strictfp', 'super', 'switch', 'synchronized', 'this', 'throw', 'throws', 'transient', 'true', 'try', 'void', 'volatile', 'while'];
    
        // Check if the package name matches the regex and does not contain restricted keywords
        return packageRegex.test(packageName) && !restrictedKeywords.some(keyword => packageName.includes(keyword));
    }

    const isValidAppName = (applicationName) => {
        const regex = /^[a-zA-Z][a-zA-Z0-9\s]{1,28}[a-zA-Z0-9]$/;
        return regex.test(applicationName);
    }

    const deployApk = () => {
        if(_.isEmpty(param.id)) {
            enqueueSnackbar('Invalid project', { autoHideDuration: 3000 })
        } else if(_.isEmpty(appName) || !isValidAppName(appName)) {
            enqueueSnackbar('Please insert a valid app name', { autoHideDuration: 3000 })
        } else if(_.isEmpty(packageName) || !isValidPackageName(packageName??'')) {
            enqueueSnackbar('Please insert a valid package name', { autoHideDuration: 3000 })
        } else if (_.isEmpty(appIcon)) {
            enqueueSnackbar('Please pick a icon for your app', { autoHideDuration: 3000 })
        } else if(_.isEmpty(websiteURL) || !isURL(websiteURL, { protocols: ['http', 'https'], require_protocol: true, require_valid_protocol: true })) {
            enqueueSnackbar('Please insert a valid website URL', { autoHideDuration: 3000 })
        } else {
            dispatch(setTryTobuild(true));
            axios.post('https://apidoxy.com/deploy', {
                projectID: param.id,
                splashScreenDuration: splashScreenDuration,
                splashScreenColor: splashScreenColor, // RGBO
                appTitle: _.trim(appTitle??''),
                appName: _.trim(appName),
                packageName: _.trim(packageName),
                websiteURL: _.trim(websiteURL),
                statusBarColor: statusBarColor, // RGBO
                appIcon: appIcon
            }).then((res) => {
                setFinalState(true);
            })
        }
    }
    return(
        <div className="flex flex-row p-2 justify-between items-center">
            <input className="modal-state" id="modal-10" type="checkbox" checked={finalState} />
            <div className="modal" role="dialog">
                <div className="modal-box">
                    <div className="card w-96 bg-base-100 shadow-xl">
                        <div className="card-body">
                                <h3 className="font-bold text-lg">Generating</h3>
                                <p className="py-4">Generating your app! Please wait a while. We will notify you via email.</p>
                                <div className="modal-action">
                                <label className="btn btn-primary" onClick={() => window.location.href = '/dashboard'}>Back to Dashboard</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-row">
                <div className="flex flex-row">
                    <div className="avatar justify-center items-center" onClick={() => window.location.href = '/dashboard'}>
                        <Icon path={mdiFolderOpenOutline} size={1} />
                    </div>
                    <div className="flex flex-col px-2">
                        <div className="text-sm font-medium select-none" >{projectTitle??''}</div>
                        <div className="text-xs text-slate-500 select-none">{_.isEmpty(projectDescription)?'Android and iOS application':projectDescription}</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="tabs gap-1">
                    <input type="radio" id="tab-1" name="tab-1" className="tab-toggle" checked={deviceType === 0} onChange={(e) => dispatch(setDeviceType(0))} />
                    <label htmlFor="tab-1" className="tab tab-pill select-none">Mobile</label>

                    <input type="radio" id="tab-2" name="tab-2" className="tab-toggle" checked={deviceType === 1} onChange={(e) => dispatch(setDeviceType(1))} />
                    <label htmlFor="tab-2" className="tab tab-pill select-none">Tab</label>
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <button className="btn btn-sm" onClick={() => dispatch(setPropertiesFor(2))}>
                    <span className="px-1">
                        <Icon path={mdiCogOutline} size={1} />
                    </span>
                    Settings
                </button>
                
                <div className="dropdown">
                    <button className={tryTobuild ? "btn btn-primary btn-sm btn-loading" : "btn btn-primary btn-sm"} tabIndex={0}>Build</button>
                    <div className="dropdown-menu dropdown-menu-bottom-left my-2 border border-gray-500" style={{ zIndex: 1000 }}>
                        <a className="dropdown-item text-sm select-none" onClick={deployApk}>Build Android App (.apk)</a>
                        <a tabIndex={-1} className="dropdown-item text-sm menu-item-disabled">Build iOS App (.ipa)</a>
                    </div>
                </div>
            </div>
        </div>
    )
}