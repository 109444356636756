import { TypeAnimation } from "react-type-animation";
import Pricing from "./pricing";
import Suscriber from "./suscriber";
import Hero from "./hero";
import Footer from "./footer";

export default function Home() {
    return(
        <div className="flex flex-col">
            <Hero />
            <Pricing />
            <div className="divider m-0 h-1"></div>
            <Suscriber />
            <Footer />
        </div>
    )
}