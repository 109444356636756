import {createSlice} from '@reduxjs/toolkit';

export const webViewSlice = createSlice({
    name: 'webView',
    initialState: {
        tryTobuild: false,
        propertiesFor: 0,
        projectTitle: '',
        projectDescription: '',
        applicationName: '',
        applicationPackage: '',
        splashTitle: '',
        splashDuration: 2,
        splashColor: '',
        websiteURL: '',
        statusBarColor: '',
        selectedAppIcon: null,
        appIcon: '',
    },
    reducers: {
        setTryTobuild: ((state, action) => {
            state.tryTobuild = action.payload;
        }),
        setSplashColor: ((state, action) => {
            state.splashColor = action.payload;
        }),
        setSplashTitle: ((state, action) => {
            state.splashTitle = action.payload;
        }),
        setProjectTitle: ((state, action) => {
            state.projectTitle = action.payload;
        }),
        setProjectDescription: ((state, action) => {
            state.projectDescription = action.payload;
        }),
        setSplashDuration: ((state, action) => {
            state.splashDuration = parseInt(action.payload);
        }),
        setPropertiesFor: ((state, action) => {
            state.propertiesFor = action.payload;
        }),
        setWebsiteURL: ((state, action) => {
            state.websiteURL = action.payload;
        }),
        setStatusBarColor: ((state, action) => {
            state.statusBarColor = action.payload;
        }),
        selectAppIcon: ((state, action) => {
            state.selectedAppIcon = action.payload;
        }),
        setApplicationPackage: ((state, action) => {
            state.applicationPackage = action.payload;
        }),
        setApplicationName: ((state, action) => {
            state.applicationName = action.payload;
        }),
        setAppIcon: ((state, action) => {
            state.appIcon = action.payload;
        }),
    }
})

export const { setTryTobuild, setProjectTitle, setProjectDescription, setSplashColor, setSplashTitle, setSplashDuration, setPropertiesFor, setWebsiteURL, setStatusBarColor, selectAppIcon, setApplicationPackage, setApplicationName, setAppIcon } = webViewSlice.actions;
export default webViewSlice.reducer;