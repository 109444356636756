import animationData from "./empty.json";
import Lottie from "react-lottie";
import { mdiApple, mdiAndroid, mdiAppleIos } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import _ from 'lodash';

import { setAccount, setOpen, setProjectDescription, setProjectTitle, setProjects } from "./dashboardSlice";

export default function Dashboard() {
    const projects = useSelector((state)=> state.dashboard.projects);
    const appTitle = useSelector((state)=> state.dashboard.appTitle);
    const appDescription = useSelector((state)=> state.dashboard.appDescription);
    const open = useSelector((state)=> state.dashboard.open);
    const account = useSelector((state) => state.dashboard.account);
    const plan = 'a';

    const dispatch = useDispatch();
    useEffect(() => {
        axios.get('https://apidoxy.com/projects')
        .then((res) => {
            if(!res.data.error) {
                dispatch(setProjects(res.data.projects));
                dispatch(setAccount(res.data.account));
            }
        })
    }, [dispatch]);
    
    const createProject = () => {
        axios.post('https://apidoxy.com/create', {
            projectTitle: _.trim(appTitle),
            projectDescription: _.trim(appDescription)
        })
        .then((res) => {
            if(!res.data.error) {
                dispatch(setProjects(res.data.projects));
                dispatch(setAccount(res.data.account));
                dispatch(setProjectTitle(''))
                dispatch(setProjectDescription(''))
                dispatch(setOpen(false));
                window.location.href = '/editor/' + res.data.recent
            }
        });
    }

    const downloadAPK = (p,n) => {
        // Replace 'url_of_your_file' with the actual URL of the file you want to download
        const fileUrl = 'https://apidoxy.com/dl/' + p + '.apk';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', n+'.apk'); // You can set a custom filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return(
        <div className="flex flex-col">
            <div className="flex flex-row flex-row justify-center items-center p-2">
                <span className="font-bold text-3xl mx-4 select-none" style={{ fontFamily: "Saira Condensed" }}>apiDoxy</span>
                <div className="flex-1"></div>
                <label className={projects.length === 5 ? "hidden" : "btn btn-solid-primary mr-2"} htmlFor="modal-1" onClick={() => dispatch(setOpen(true))}>New project</label>
                <input className="modal-state" id="modal-1" type="checkbox" checked={open} />
                <div className="modal">
                    <label className="modal-overlay" htmlFor="modal-1"></label>
                    <div className="modal-content flex flex-col gap-5">
                        <label htmlFor="modal-1" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => dispatch(setOpen(false))}>✕</label>
                        <h2 className="text-xl">Create a project</h2>
                        <div className="w-80">
                            <label className="sr-only" htmlFor="projectName">Project name</label>
                            <input className="input input-solid" value={appTitle} placeholder="Project name" type="text" id="projectName" onChange={(e) => dispatch(setProjectTitle(e.target.value))} />
                        </div>
                        <div className="w-full">
                            <label className="sr-only" htmlFor="description">Description (Optional)</label>
                            <textarea className="textarea textarea-solid max-w-full" placeholder="Description (Optional)" rows="8" id="description" onChange={(e) => dispatch(setProjectDescription(e.target.value))}>{appDescription}</textarea>
                        </div>

                        <div className="flex gap-3">
                            <label htmlFor="modal-1" className="btn btn-block" onClick={() => dispatch(setOpen(false))}>Cancel</label>
                            <button className="btn bg-green-800 btn-block" disabled={_.trim(appTitle).length === 0} onClick={createProject}>Create</button>
                        </div>
                    </div>
                </div>
                <div className="popover popover-border">
                    <label className="popover-trigger my-2 avatar" tabIndex="0">
                        <img src={account.image} alt={account.name} />
                    </label>
                    <div className="popover-content popover-bottom-left bordered" tabIndex="0">
                        <div className="popover-arrow"></div>
                        <div className="flex flex-col items-center p-0">
                            <div className="text-md px-2 pt-4 pb-1">{account.name}</div>
                            <div className="text-sm px-2">{account.email}</div>
                            <div className="divider w-full h-1"></div>
                            <button className="btn btn-solid-primary mb-2" onClick={() => window.location.href="../auth/signout"}>Logout</button>
                        </div>
                    </div>
                </div>

            </div>
            
            <div class="divider m-0 h-1"></div>
            <div className="flex flex-wrap gap-3 justify-center p-2">
                {
                    projects.length === 0 ?
                    <div className="flex flex-col items-center">
                        <Lottie options={{animationData: animationData,}} height={520} width={520}/>
                        <span className="text-md italic text-slate-400 select-none">Create a project first</span>
                    </div>:
                    projects.map((project, index) => (
                        <div className="card" key={index}>
                            <div className="card-body">
                                <div className="flex flex-row">
                                <div class="avatar-group">
                                    <div class="avatar items-center justify-center">
                                        <Icon path={ mdiAndroid } color={'grey'} size={1} />
                                    </div>
                                    <div class="avatar items-center justify-center">
                                        <Icon path={ mdiApple } color={'grey'} size={1} />
                                    </div>
                                </div>
                                </div>
                                <span className="text-2xl font-bold truncate">{project.projectTitle}</span>
                                <p className="text-content2">{project.projectDescription}</p>
                                <div className="card-footer justify-start gap-2">
                                    <button className={project.apk === "loading" ? "btn btn-outline-primary btn-loading" : "btn btn-outline-primary"} onClick={() => downloadAPK(project._id, project.projectTitle)} disabled={project.apk === 'none'}>
                                        <Icon path={ mdiAndroid } color={'grey'} size={1.3} />
                                    </button>
                                    <button className={project.ipa === "loading" ? "btn btn-outline-primary btn-loading" : "btn btn-outline-primary"} onClick={() => (window.open('/dl/'+project._id+'.ipa', '_blank'))} disabled={project.ipa === 'none'}>
                                        <Icon path={ mdiAppleIos } color={'grey'} size={1.3} />
                                    </button>
                                    <button className="btn" onClick={()=> {window.location.href = '../editor/' + project._id}}>Edit</button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}