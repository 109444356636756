import {createSlice} from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        projects: [],
        appTitle: '',
        appDescription: '',
        open: false,
        account: {
            name: '',
            email: '',
            image: '',
        }
    },
    reducers: {
        setProjects: ((state, action) => {
            state.projects = action.payload;
        }),
        setProjectTitle: ((state, action) => {
            state.appTitle = action.payload;
        }),
        setProjectDescription: ((state, action) => {
            state.appDescription = action.payload;
        }),
        setOpen: ((state, action) => {
            state.open = action.payload;
        }),
        setAccount: ((state, action) => {
            state.account = {...action.payload}
        })
    }
})

export const { setProjects, setProjectTitle, setProjectDescription, setOpen, setAccount } = dashboardSlice.actions;
export default dashboardSlice.reducer;