import {createSlice} from '@reduxjs/toolkit';

export const deviceSlice = createSlice({
    name: 'device',
    initialState: {
        deviceType: 0
    },
    reducers: {
        setDeviceType: ((state, action) => {
            state.deviceType = action.payload;
        }),
    }
})

export const { setDeviceType } = deviceSlice.actions;
export default deviceSlice.reducer;