export default function Privacy() {
    return(
        <div class="max-w-4xl mx-auto px-4 py-8">
            <h1 class="text-3xl font-bold mb-6">Privacy Policy of Apidoxy</h1>
            
            <p class="text-gray-700 mb-8">Last update: March 2024</p>

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Introduction</h2>
                <p class="text-gray-700">Welcome to Apidoxy’s Privacy Policy.</p>
                <p class="text-gray-700">At Apidoxy, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the information you provide when you use our platform. Your privacy is important to us, and we strive to be transparent about our data practices. We have implemented robust measures to protect your information and comply with applicable data protection laws. Please take a moment to review this Privacy Policy to understand how we handle your data and the choices you have regarding its use. If you have any questions or concerns, please don't hesitate to contact us at <a href="mailto:support@apidoxy.com" class="text-blue-500">support@apidoxy.com</a>.</p>
                <p class="text-gray-700">Thank you for trusting Apidoxy with your information.</p>
            </div>

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Terminology</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">"Apidoxy" (collectively referred to as "we", "us" or "our") denotes the mobile application creation service provided by Apidoxy.</li>
                    <li class="text-gray-700">"Platform" refers to the website, software or service provided by Apidoxy.</li>
                    <li class="text-gray-700">"You" or "User" refers to any individual who accesses or utilizes Apidoxy's software, platform, or services.</li>
                </ol>
            </div>

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Information We Collect</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">Personal Information: When you create an account, we may collect personal information such as your name, gender, date of birth, email address, and payment information.</li>
                    <li class="text-gray-700">Usage Information: We may collect information about how you interact with our platform, including your IP address and device information.</li>
                </ol>
            </div>

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Use of Information</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">We use the information we collect to provide and improve our platform, communicate with you, and personalize your experience.</li>
                    <li class="text-gray-700">We may also use your information to send you promotional materials or updates about our services.</li>
                </ol>
            </div>

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Cookie Policy</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">Cookies are small text files stored on your device that track your browsing activity and preferences.</li>
                    <li class="text-gray-700">We use cookies and similar technologies on our website and platform to enhance user experience and provide personalized content.</li>
                    <li class="text-gray-700">We use both essential and non-essential cookies. Essential cookies are necessary for the functioning of the website, while non-essential cookies help us analyze website usage and tailor content to your interests.</li>
                    <li class="text-gray-700">You can choose to accept or decline cookies through your browser settings. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, please note that disabling cookies may limit your ability to access certain features of our website and platform.</li>
                    <li class="text-gray-700">We may use third-party cookies on our website and platform for analytics, advertising, and other purposes. These cookies are set and controlled by third-party service providers, such as Google Analytics or advertising networks.</li>
                    <li class="text-gray-700">Third-party cookies are subject to the privacy policies and practices of the third-party providers. We do not have control over these cookies or the data they collect.</li>
                </ol>
            </div>

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Data Sharing</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">We do not sell or rent your personal information to third parties.</li>
                    <li class="text-gray-700">We may share your information with third-party service providers who assist us in operating our platform or conducting our business.</li>
                </ol>
            </div>
            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Data Security</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</li>
                </ol>
            </div>
            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Data Retention</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</li>
                </ol>
            </div>
            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Your Rights</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">You have the right to access, update, or delete your personal information at any time.</li>
                    <li class="text-gray-700">You may also have the right to restrict or object to the processing of your personal information.</li>
                </ol>
            </div>
            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Children's Privacy</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">Our platform is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under this age.</li>
                </ol>
            </div>
            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Changes to this Policy</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</li>
                </ol>
            </div>
            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-4">Contact Us</h2>
                <ol class="list-disc list-inside">
                    <li class="text-gray-700">If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@apidoxy.com" class="text-blue-500">support@apidoxy.com</a>.</li>
                </ol>
            </div>
        </div>
    )
}