
import Icon from '@mdi/react';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { isURL } from 'validator';
import { setStatusBarColor, setWebsiteURL } from './webViewSlice';
import { mdiCheck, mdiAlertCircleOutline  } from '@mdi/js';

export default function WebProperties() {
    const dispatch = useDispatch();
    const statusBarColor = useSelector((state) => state.webView.statusBarColor);
    const websiteURL = useSelector((state) => state.webView.websiteURL);
    const URLChange = (e) => {
        dispatch(setWebsiteURL(e.target.value));
    }
    const URLvalidation = (url) => {
        if(isURL(url, { protocols: ['http', 'https'], require_protocol: true, require_valid_protocol: true })) {
            return true
        } else {
            return false
        }
    }

    
    
    return(
        <div className="flex flex-1 max-w-72">
            <aside className="sidebar h-full flex flex-col justify-start flex-1">
                <section className="sidebar-content h-full overflow-visible">
                    <nav className="menu rounded-md">
                        <section className="menu-section px-4">
                            <span className="menu-title select-none">Properties</span>
                            <label class="form-control w-full max-w-xs flex flex-col">
                                <div class="label ml-1">
                                    <span class="label-text">Website URL</span>
                                </div>
                                <div className="p-1 flex flex-row items-center">
                                    <input className="input input-solid" placeholder="Website URL" value={websiteURL} onChange={(e) => URLChange(e)} />
                                    <div className="ml-2 tooltip tooltip-left tooltip-primary before:w-[15rem] before:content-[attr(data-tip)]" data-tip="Please type a valid URL with protocol">
                                        <Icon path={ URLvalidation(websiteURL) ? mdiCheck : mdiAlertCircleOutline } color={'green'} size={0.8} />
                                    </div>
                                </div>
                            </label>
                            <div className="p-1 popover w-full">
                                <label class="form-control w-full max-w-xs flex flex-col">
                                    <div class="label ml-1">
                                        <span class="label-text">Status bar color</span>
                                    </div>
                                    <input className="popover-trigger input input-solid" placeholder="Status bar color" readOnly value={statusBarColor}/>
                                    <div className="popover-content mt-16 p-0">
                                        <SketchPicker width={205} onChangeComplete={(c) => dispatch(setStatusBarColor(c.hex))} />
                                    </div>
                                </label>
                            </div>
                        </section>
                    </nav>
                </section>
            </aside>
        </div>
    )
}