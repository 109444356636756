import Icon from '@mdi/react';
import { mdiCodeTags, mdiCropPortrait, mdiLockOutline } from '@mdi/js';
import { useDispatch } from 'react-redux';
import { setPropertiesFor } from './webViewSlice';
export default function WebViewSideNav() {
    const dispatch = useDispatch();
    const components = [
        {
            name: 'Splash Screen',
            icon: mdiCropPortrait,
            lock: true,
        },
        {
            name: 'Web Screen',
            icon: mdiCodeTags,
            lock: true,
        },
    ]
    return(
        <div className="flex flex-1 max-w-72">
            <aside className="sidebar h-full justify-start m-0">
                <section className="sidebar-content h-fit min-h-[20rem] overflow-visible">
                    <nav className="menu rounded-md">
                        <section className="menu-section px-4">
                            <span className="menu-title select-none">Components</span>
                            <ul className="menu-items">
                                {
                                    components.map((component, index) => (
                                        <li className="menu-item justify-between" key={index} onClick={() => dispatch(setPropertiesFor(index))}>
                                            <div className="flex flex-row items-center gap-1">
                                                <Icon path={component.icon} size={1} />
                                                <span>{component.name}</span>
                                            </div>
                                            {
                                                component.lock?
                                                <Icon path={mdiLockOutline} size={0.8} /> : <></>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </section>
                    </nav>
                </section>
            </aside>
        </div>
    )
}