import { useDispatch, useSelector } from "react-redux";
import WebProperties from "./webProperties";
import SplashProperties from "./splashProperties";
import WebViewAppBar from "./webViewAppBar"
import WebViewDevice from "./webViewDevice"
import WebViewSideNav from "./webViewSideNav"
import Settings from "./settings";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { setAppIcon, setApplicationName, setApplicationPackage, setProjectDescription, setProjectTitle, setSplashColor, setSplashDuration, setSplashTitle, setStatusBarColor, setWebsiteURL } from "./webViewSlice";



export default function WebViewEditor() {
    const propertiesFor = useSelector((state) => state.webView.propertiesFor);
    const param = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        axios.post('https://apidoxy.com/project', {
            id: param.id
        }).then((res) => {
            if(!res.data.error) {
                dispatch(setProjectTitle(res.data.project.projectTitle??''))
                dispatch(setProjectDescription(res.data.project.projectDescription??''))
                dispatch(setApplicationName(res.data.project.data.appName??''))
                dispatch(setSplashDuration(res.data.project.data.splashScreenDuration??1))
                dispatch(setSplashColor(res.data.project.data.splashScreenColor??''))
                dispatch(setWebsiteURL(res.data.project.data.websiteURL??''))
                dispatch(setStatusBarColor(res.data.project.data.statusBarColor??''))
                dispatch(setSplashTitle(res.data.project.data.appTitle??''))
                dispatch(setApplicationPackage(res.data.project.data.packageName??''))
                dispatch(setAppIcon(res.data.project.data.appIcon??''))
            }
        })
    }, [dispatch])
    return(
        <div className="flex flex-col h-full flex-1">
            <WebViewAppBar />
            <div className="divider m-0 h-1"></div>
            <div className="flex flex-row justify-between flex-1">
                <WebViewSideNav />
                <div className="divider divider-vertical m-0 p-0 w-1 h-full"></div>
                <WebViewDevice />
                <div className="divider divider-vertical m-0 p-0 w-1 h-full"></div>
                {
                    propertiesFor === 0 ?
                    <SplashProperties />:
                    propertiesFor === 1 ? 
                    <WebProperties /> : <Settings />
                }
            </div>
        </div>
    )
}