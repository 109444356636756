import moment from "moment";

export default function Footer() {
    return(
      <footer class="bg-gray-800 text-white py-4 px-3 mt-0">
        <div class="container mx-auto flex flex-wrap items-center justify-between">
            <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
                <p class="text-xs text-gray-400 md:text-sm">Copyright {moment().format('YYYY')} &copy; All Rights Reserved by apiDoxy</p>
            </div>
            <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
                <ul class="list-reset flex justify-center flex-wrap text-xs md:text-sm gap-3">
                    <li><a href="https://www.linkedin.com/company/apidoxy/" class="text-gray-400 hover:text-white">Linkedin</a></li>
                    <li><a href="mailto:support@apidoxy.com" class="text-gray-400 hover:text-white">Contact</a></li>
                    <li class="mx-4"><a href="/privacy" class="text-gray-400 hover:text-white">Privacy Policy</a></li>
                    <li><a href="/tos" class="text-gray-400 hover:text-white">Terms of Use</a></li>
                </ul>
            </div>
        </div>
    </footer>
    )
}